define("ember-leaflet/components/geojson-layer", ["exports", "ember-leaflet/components/base-layer"], function (_exports, _baseLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#each this.leafletDescriptorsProps as |prop|}}
    {{did-update (fn this.updateOption prop) (get this.args prop)}}
  {{/each}}
  
  {{did-update this.didChangeGeojson @geoJSON}}
  
  <@node @didInsertParent={{this.didInsertParent}} @willDestroyParent={{this.willDestroyParent}} as |Node|>
    {{#let (ember-leaflet-hash) as |components|}}
      {{#each this.componentsToYield as |c|}}
        {{ember-leaflet-assign-to
          components
          key=(ember-leaflet-or c.as c.name)
          value=(component (ensure-safe-component c.name) parent=this node=Node)
          onChange=this.mergeComponents
        }}
      {{/each}}
  
      {{yield this.mergedComponents}}
    {{/let}}
  </@node>
  */
  {
    "id": "EyNK/s9K",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"leafletDescriptorsProps\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,2],[[28,[37,3],[[30,0,[\"updateOption\"]],[30,1]],null],[28,[37,4],[[30,0,[\"args\"]],[30,1]],null]],null]],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[1,[28,[35,2],[[30,0,[\"didChangeGeojson\"]],[30,2]],null]],[1,\"\\n\\n\"],[8,[30,3],null,[[\"@didInsertParent\",\"@willDestroyParent\"],[[30,0,[\"didInsertParent\"]],[30,0,[\"willDestroyParent\"]]]],[[\"default\"],[[[[1,\"\\n\"],[44,[[28,[37,6],null,null]],[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"componentsToYield\"]]],null]],null],null,[[[1,\"      \"],[1,[28,[35,7],[[30,5]],[[\"key\",\"value\",\"onChange\"],[[28,[37,8],[[30,6,[\"as\"]],[30,6,[\"name\"]]],null],[50,[28,[37,10],[[30,6,[\"name\"]]],null],0,null,[[\"parent\",\"node\"],[[30,0],[30,4]]]],[30,0,[\"mergeComponents\"]]]]]],[1,\"\\n\"]],[6]],null],[1,\"\\n    \"],[18,7,[[30,0,[\"mergedComponents\"]]]],[1,\"\\n\"]],[5]]]],[4]]]]]],[\"prop\",\"@geoJSON\",\"@node\",\"Node\",\"components\",\"c\",\"&default\"],false,[\"each\",\"-track-array\",\"did-update\",\"fn\",\"get\",\"let\",\"ember-leaflet-hash\",\"ember-leaflet-assign-to\",\"ember-leaflet-or\",\"component\",\"ensure-safe-component\",\"yield\"]]",
    "moduleName": "ember-leaflet/components/geojson-layer.hbs",
    "isStrictMode": false
  });

  /**
   * Represents a GeoJSON object or an array of GeoJSON objects. Allows you to render GeoJSON
   * data and display it on the map.
   *
   * @class GeojsonLayer
   * @extends BaseLayer
   */
  let GeojsonLayer = (_dec = Ember._action, (_class = class GeojsonLayer extends _baseLayer.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "leafletRequiredOptions", [...this.leafletRequiredOptions,
      /**
       * An object in GeoJSON format to display on the map.
       *
       * @argument geoJSON
       * @type {Object}
       */
      'geoJSON']);

      _defineProperty(this, "leafletOptions", [...this.leafletOptions,
      /**
       * A Function defining how GeoJSON points spawn Leaflet layers. It is internally called when data is added,
       * passing the GeoJSON point feature and its LatLng.
       *
       * @argument pointToLayer
       * @type {Function}
       */
      'pointToLayer',
      /**
       * A Function defining the Path options for styling GeoJSON lines and polygons, called internally when data is added.
       *
       * @argument style
       * @type {Function}
       */
      'style',
      /**
       * A Function that will be called once for each created Feature, after it has been created and styled. Useful
       * for attaching events and popups to features.
       *
       * @argument onEachFeature
       * @type {Function}
       */
      'onEachFeature',
      /**
       * A Function that will be used to decide whether to include a feature or not.
       *
       * @argument filter
       * @type {Function}
       */
      'filter',
      /**
       * A Function that will be used for converting GeoJSON coordinates to LatLngs.
       * The default is the coordsToLatLng static method.
       *
       * @argument coordsToLatLng
       * @type {Function}
       */
      'coordsToLatLng',
      /**
       * Whether default Markers for `Point` type Features inherit from group options.
       * Defaults to `false`.
       *
       * @argument markersInheritOptions
       * @type {Boolean}
       */
      'markersInheritOptions']);

      _defineProperty(this, "leafletEvents", [...this.leafletEvents,
      /**
       * Fired when a layer is added to this FeatureGroup.
       *
       * @argument onLayeradd
       * @type {Function}
       */
      'layeradd',
      /**
       * Fired when a layer is removed from this FeatureGroup.
       *
       * @argument onLayerremove
       * @type {Function}
       */
      'layerremove']);

      _defineProperty(this, "leafletDescriptors", [...this.leafletDescriptors, 'style']);

      _defineProperty(this, "componentsToYield", [...this.componentsToYield, {
        name: 'popup-layer',
        as: 'popup'
      }, {
        name: 'tooltip-layer',
        as: 'tooltip'
      }]);
    }

    didChangeGeojson(geoJSON) {
      if (geoJSON) {
        this.pushDataToLeaflet(geoJSON);
      }
    }

    pushDataToLeaflet(geoJSON) {
      if (!this._layer) {
        return;
      } // recall that GeoJSON layers are actually layer groups -- we have to clear
      // their contents first...


      this._layer.clearLayers(); // we need to update the group layers options before re-adding geojson
      // otherwise, they wouldn't get the changes that could be happening meanwhile


      this._layer.options = this.options;

      if (geoJSON) {
        // ...then add new data to recreate the child layers in an updated form
        this._layer.addData(geoJSON);
      }
    }

    createLayer() {
      return this.L.geoJson(...this.requiredOptions, this.options);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "didChangeGeojson", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "didChangeGeojson"), _class.prototype)), _class));
  _exports.default = GeojsonLayer;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GeojsonLayer);
});