define("ember-leaflet/components/polyline-layer", ["exports", "ember-leaflet/components/array-path-layer"], function (_exports, _arrayPathLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * A class for drawing polyline overlays on a map.
   *
   * @class PolylineLayer
   * @extends ArrayPathLayer
   */
  class PolylineLayer extends _arrayPathLayer.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "leafletOptions", [...this.leafletOptions,
      /**
       * How much to simplify the polyline on each zoom level.
       * More means better performance and smoother look, and less
       * means more accurate representation.
       * Defaults to `1.0`.
       *
       * @argument smoothFactor
       * @type {Number}
       */
      'smoothFactor',
      /**
       * Disable polyline clipping.
       * Defaults to `false`.
       *
       * @argument noClip
       * @type {Boolean}
       */
      'noClip']);
    }

    createLayer() {
      return this.L.polyline(...this.requiredOptions, this.options);
    }

  }

  _exports.default = PolylineLayer;
});