define("ember-initials/utils/generators/svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Svg {
    generate(properties) {
      const textElement = this._generateElement('text', properties.initials, {
        style: this._stringify(properties.textStyles, (key, value) => `${key}: ${value};`),
        y: '50%',
        x: '50%',
        dy: '0.35em',
        'text-anchor': 'middle',
        'pointer-events': 'none',
        fill: properties.initialsColor
      });

      return this._generateElement('svg', textElement, {
        style: this._stringify(properties.backgroundStyles, (key, value) => `${key}: ${value};`),
        xmlns: 'http://www.w3.org/2000/svg',
        'pointer-events': 'none',
        viewBox: '0 0 100 100'
      });
    }

    _generateElement(name, content) {
      let attrs = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      const attrsString = this._stringify(attrs, (key, value) => `${key}="${value}"`);

      return `<${name} ${attrsString}>${content}</${name}>`;
    }

    _stringify() {
      let object = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let transform = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};
      return Object.entries(object).map(_ref => {
        let [key, value] = _ref;
        return transform(key, value);
      }).join(' ');
    }

  }

  _exports.default = Svg;
});