define("ember-initials/utils/store", ["exports", "mini-svg-data-uri", "ember-initials/utils/generators/svg"], function (_exports, _miniSvgDataUri, _svg) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Store {
    constructor() {
      _defineProperty(this, "cache", new Map());

      _defineProperty(this, "svgGenerator", new _svg.default());
    }

    get length() {
      return this.cache.size;
    }

    clear() {
      this.cache.clear();
    }

    getItem(properties) {
      const key = JSON.stringify(properties);
      return this.cache.get(key) || this._create(key, properties);
    }

    _create(key, properties) {
      const element = this.svgGenerator.generate(properties);
      const data = (0, _miniSvgDataUri.default)(element);
      return this.cache.set(key, data) && data;
    }

  }

  _exports.default = Store;
});