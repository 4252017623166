define("ember-leaflet/components/array-path-layer", ["exports", "ember-leaflet/components/path-layer"], function (_exports, _pathLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * A class that extends `PathLayer` with a `@locations` argument.
   * Layers that require multiple points extend this class.
   *
   * @class ArrayPathLayer
   * @extends ember-leaflet/components/path-layer~PathLayer
   */
  class ArrayPathLayer extends _pathLayer.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "leafletRequiredOptions", [...this.leafletRequiredOptions,
      /**
       * An array of geographical points.
       *
       * @argument locations
       * @type {LatLng[]}
       */
      'locations']);

      _defineProperty(this, "leafletDescriptors", [...this.leafletDescriptors, 'locations:setLatLngs']);
    }

  }

  _exports.default = ArrayPathLayer;
});