define("ember-set-body-class/services/body-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class BodyClassService extends Ember.Service {
    constructor() {
      super(...arguments);

      _defineProperty(this, "_dom", Ember.getOwner(this).lookup('service:-document'));

      _defineProperty(this, "_fastboot", Ember.getOwner(this).lookup('service:fastboot'));

      _defineProperty(this, "registrations", new Map());
    }

    register(id, classNames) {
      this.registrations.set(id, classNames);
      this.scheduleUpdate();
    }

    deregister(id) {
      this.registrations.delete(id);
      this.scheduleUpdate();
    }

    get names() {
      let allNames = new Set();

      for (let classNames of this.registrations.values()) {
        for (let className of classNames) {
          allNames.add(className);
        }
      }

      return [...allNames];
    }

    scheduleUpdate() {
      this.scheduledRun = Ember.run.once(this, this.updateBodyClass);
    }

    updateBodyClass() {
      if (!this._dom) {
        return;
      }

      let registeredClassNames = this.names;
      let body = this._dom.body;
      let attr = body.getAttribute('class');
      let classList = Ember.A(attr ? attr.split(/\s+/) : []);
      classList.removeObjects(this._previousNames || []);
      classList.addObjects(registeredClassNames);
      this._previousNames = registeredClassNames;
      body.setAttribute('class', classList.join(' '));
    }

    willDestroy() {
      if (this._fastboot && this._fastboot.isFastBoot) {
        // prevent FastBoot from removing the CSS classes
        // again before the response is sent out
        Ember.run.cancel(this.scheduledRun);
      }
    }

  }

  _exports.default = BodyClassService;
});